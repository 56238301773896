<style>
    .demo-tabs-style1 > .ivu-tabs-card > .ivu-tabs-content {
        height: 120px;
        margin-top: -16px;
    }

    .demo-tabs-style1 > .ivu-tabs-card > .ivu-tabs-content > .ivu-tabs-tabpane {
        background: #fff;
        padding: 16px;
    }

    .demo-tabs-style1 > .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab {
        border-color: transparent;
    }

    .demo-tabs-style1 > .ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab-active {
        border-color: #fff;
    }
    .demo-tabs-style2 > .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab{
        border-radius: 0;
        background: #fff;
    }
    .demo-tabs-style2 > .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab-active{
        border-top: 1px solid #3399ff;
    }
    .demo-tabs-style2 > .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab-active:before{
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: #3399ff;
        position: absolute;
        top: 0;
        left: 0;
    }
</style>
<template>
    <div style="background: #ffffff;height:90%;margin:auto;margin-top:0px;padding:0px;">
        <loginfobar v-if="!isV2" style="width:100%;margin:auto;margin-top:0px;padding:0px 40px 0px"/>
        <div style="margin:auto;width:100%;background:#cbd4f4;padding-bottom:15px">
            <h1 v-if="!isV2" style="padding-top:8px">采购部门业务管理系统</h1>
            <Tabs type="card" style="background: #e3e8ee;margin:auto;padding:10px;margin-top:1%;width:100%;">
                <TabPane label="计划采购任务批次">
                    <purchasepici />
                </TabPane>
                <!--
                <TabPane label="计划采购任务">
                    <purchaseorder departid="106" type="0"/>
                </TabPane>
                -->

                <TabPane label="零散采购任务">
                    <purchaseorder departid="106" type="1"/>
                </TabPane>
                <TabPane  label="销售预测计划">
                    <splanorder departid="106"/>
                </TabPane>
                <TabPane label="BOM单">
                    <bom caller="viewer" />
                </TabPane>
            </Tabs>
        </div>
    </div>

</template>
<script>
    import UserInfoTitleBar from '@/views/common/UserInfoTitleBar.vue'
    import PurchaseOrder from '@/views/business/workshop/purchase/PurchaseOrder'
    import PurchasePici from '@/views/business/workshop/purchase/PurchasePici'
    import PlanOrder from '@/views/business/workshop/plan/PlanOrder'
    import SalePlanOrder from '@/views/business/workshop/sell/saleplan/SalePlanOrder'
    import MasterModel from '@/views/business/workshop/technology/MasterModel'

    export default {
        name:'PurchaseDesktop',
        components:{
            'planorder': PlanOrder,
            'purchaseorder':PurchaseOrder,    
            'purchasepici':PurchasePici,
            'splanorder':SalePlanOrder,
            'loginfobar':UserInfoTitleBar,
            'bom':MasterModel
        },
        computed:{
            isV2() {
                let path = this.$route.path
                if(path.indexOf("/business/workhome")!=-1){
                    return true
                }
                return false
            }
        },        
    }
</script>
