<template>
<div >
    <div style="height:40px;width:100%;">
        <h2>计划采购任务批次</h2>
    </div>
    
    <!-- 部门账号列表-->
    <div>    
        <Form ref="orderQueryForm" :model="formInline" label-position="right" :label-width="130" style="border:1px solid lightgray;background:lightblue;">
            <table width="100%">
                <tr>        
                    <td align="left" style="width: 20%;">
                        <FormItem prop="splanOrdercode"  label="销售计划单号:" style="margin-bottom:5px;margin-top:10px">
                            <i-input type="text" v-model="formInline.splanOrdercode"></i-input>
                        </FormItem>  
                    </td>
                    <td align="left" style="width: 15%;">
                        <FormItem prop="createDate"  label="下单月份:" style="margin-bottom:5px;margin-top:10px">
                            <DatePicker type="month" format="yyyy-MM" placeholder="选择查询月份" v-model="formInline.createDate"  @on-change="onCreateMonthChange" @on-clear="formInline.createDate=null"></DatePicker>
                        </FormItem>  
                    </td>       
                    <td align="left" style="width: 15%;">
                        <FormItem prop="expectDate"  label="采购月度:" style="margin-bottom:5px;margin-top:10px">
                            <DatePicker type="month" format="yyyy-MM" placeholder="选择查询月份" v-model="formInline.expectDate"  @on-change="onMonthChange" @on-clear="formInline.expectDate=null"></DatePicker>
                        </FormItem>  
                    </td>
                    <td  colspan="4" align="right">
                        <div>
                            <Button type="primary" @click="handleQuery('vehicleQueryForm')" >采购单查询</Button>    
                            <Button  @click="handleReset('orderQueryForm')" style="margin-left:10px">重置</Button>
                        </div>
                    </td>
                </tr>
            </table>
        </Form>    
        <Table border :columns="tblcolumns" :data="tbldata" >
        <template slot-scope="{ row }" slot="fromwhere">
            <strong>{{ row.splanOrdercode+"(第"+row.pici+"批次)" }}</strong>
        </template>
        <template slot-scope="{ row, index }" slot="action">
            <div class="opbar">
                <Button type="primary" size="small"  @click="view(row)">查看</Button>
            </div>
        </template>
        </Table>
        <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:5px;float:right" @on-change="onpagechange"/>
    </div>
    <Modal v-model="picidata.showdlg" title="采购批次详情" width="90%" scrollable footer-hide>
        <div>
            <h1 v-if="picidata.data !=null">{{picidata.data.splanOrdercode+"(第"+picidata.data.pici+"批次)"}}</h1>
            <porder ref="plist" departid="106" type="0" />
        </div>
        <div slot="footer">
            <Button size="large" @click="picidata.showdlg=false">关闭详情</Button>
        </div>
    </Modal>
    </div>
</template>

<script>
import * as sysTool from '@/zsjs/systemTool.js'
import PurchaseOrder from '@/views/business/workshop/purchase/PurchaseOrder'
export default {
    name:'PurchasePici',
    data () {
        return {
            formInline:{
                companyId:this._self.$root.$store.state.LogonInfo.companyid,
                createDate:sysTool.getNowFormatDate(new Date()),
                expectDate:null,
                splanOrdercode:null,
                splitType:0,
                pageSize:40,
                pageNum:0
            },
            tblpageinfo:{
                total:0,
                currentpage:1,
                pagesize:40
            },
            tblcolumns: [
                {
                    type: 'index',
                    width: 60,
                    align: 'center'
                },
                {
                    title: '来源',
                    slot: 'fromwhere',         
                },
                {
                    title: '数量',
                    key: 'num',         
                },
                {
                    title: '预计到货日期',
                    key: 'expectDate'
                },
                {
                    title: '创建时间',
                    key: 'createDate'
                },
                {
                    title: '操作',
                    slot: 'action',
                    align: 'center'
                }
                
            ],
            tbldata: [],
            picidata:{
                showdlg:false,
                data:null
            }
        }
    },
    mounted (){

    },
    methods: {
        handleQuery(){
            var postdata = JSON.parse(JSON.stringify(this.formInline))
                if(this.formInline.expectDate !=null && this.formInline.expectDate!=''){
                    if(typeof(this.formInline.expectDate) == 'object'){
                        postdata.expectDate = sysTool.getNowFormatDate(this.formInline.expectDate)
                    }
                    else{
                        postdata.expectDate = this.formInline.expectDate
                    }
                    
                }

                if(this.formInline.createDate !=null && this.formInline.createDate!=''){
                    if(typeof(this.formInline.createDate) == 'object'){
                        postdata.createDate = sysTool.getNowFormatDate(this.formInline.createDate)
                    }
                    else{
                        postdata.createDate = this.formInline.createDate
                    }
                    
                }

                if(postdata.splanOrdercode !=null){
                    postdata.splanOrdercode = this.formInline.splanOrdercode.trim()
                }

                for(var p in postdata){
                    if(postdata[p] == null || postdata[p] == ''){
                        delete postdata[p]
                    }
                }

                console.log("采购计划批次查询",postdata)
                this.$axios.post("/api/saleplan/pici/query",postdata)
                .then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data.data
                        console.log(res.data.data)
                        if(rspdata == null){
                            this.tbldata=[]
                        }
                        else{
                            this.tblpageinfo.total = res.data.data.totalnumber
                            this.tbldata =rspdata
                        }
                    }
                    else{
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                }.bind(this));   
        },

        onMonthChange(e){
            if(e == null || e==''){
                this.formInline.expectDate =null
            }
            else{
                this.formInline.expectDate=e+'-01'
            }
        },
        onCreateMonthChange(e){
            if(e == null || e==''){
                this.formInline.createDate =null
            }
            else{
                this.formInline.createDate=e+'-01'
            }
        },
        onPurchaseExpectdateChange(e){
            this.dopurchase.formInline.expectDate=e
        },
        onpagechange(v){
            this.tblpageinfo.currentpage = v
            this.formInline.pageNum = v
            this.handleQuery()
        },
        view(data){
            this.picidata.data = data
            this.$refs['plist'].loaddata(data)
            this.picidata.showdlg = true
        },

    },
    components:{
        porder: PurchaseOrder
    },
    
}
</script>
<style>
    .opbar {
        display: inline-flex;
        align-items: center;
    }

    .opbar button{
        margin:0px 4px;
    }
</style>